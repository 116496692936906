import { LoadingContainer, StyledSpinner } from './styles/LoadingIndicator'

const LoadingIndicator = ({ display, width, marginTop }) => (
	<LoadingContainer
		style={{ width: width, display: display, marginTop: marginTop }}
	>
		<StyledSpinner viewBox="0 0 50 50">
			<circle
				className="path"
				cx="25"
				cy="25"
				r="20"
				fill="none"
				strokeWidth="4"
			/>
		</StyledSpinner>
	</LoadingContainer>
)

export default LoadingIndicator
