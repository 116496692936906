import React from 'react'
import ReactDOM from 'react-dom'

import CommentHolder from '../CommentHolder/CommentHolder'

class Comments extends React.Component {
	constructor(props) {
		super(props)
		this.addComments = this.addComments.bind(this)
		this.state = {
			clicked: false
		}
		// this.page = sessionStorage.getItem("page");
		this.props = props
	}

	filterData = () => {
		const { notes, currentPage } = this.props
		this.page = currentPage
		// this.headingSize = document.getElementById('header').offsetHeight;
		this.headingSize = 105
		this.commentHeading = 55

		// sort data by y in ascending order
		// const data_obj = { ...localStorage };
		// const data_obj = localStorage.getItem("bookmarks");
		const data_obj = notes

		this.savedData = {}
		// const values = Object.values(data_obj);
		let values = data_obj ? data_obj : []

		values.sort((a, b) => {
			// let a_obj = JSON.parse(a);
			// let b_obj = JSON.parse(b);
			let a_obj = a
			let b_obj = b
			return a_obj.note_anchor.y - b_obj.note_anchor.y
		})

		let counter = 0
		for (let v of values) {
			// let tmp = JSON.parse(v);
			let tmp = v
			this.savedData[`${tmp.note_keyword}_${counter}`] = v
			counter++
		}

		this.renderSavedData(this.savedData)
	}
	renderSavedData = (data) => {
		const { notes, getNoteByBookPage, bookTitle, expiration } = this.props
		// this.page = sessionStorage.getItem("page");
		// this.page=currentPage;
		let container = document.getElementById('comments-container')
		container.innerHTML = ''
		for (let d in data) {
			// let payload = JSON.parse(data[d]);
			let payload = data[d]

			if (payload.note_page === this.page) {
				let div = document.createElement('div')
				container.appendChild(div)
				// console.log("Y Payload: ", payload.y, this.headingSize);
				ReactDOM.render(
					<CommentHolder
						x={payload.note_anchor.x}
						deviceHeight={payload.note_anchor.deviceHeight}
						y={payload.note_anchor.y}
						note_id={payload._id}
						text={payload.note_keyword}
						page={this.page}
						start={payload?.note_highlight?.start}
						end={payload?.note_highlight?.end}
						notes={notes}
						note_payload={payload}
						currentHeight={this.props.pageHeight}
						isRender={true}
						getNoteByBookPage={getNoteByBookPage}
						bookTitle={bookTitle}
						expiration={expiration}
					/>,
					div
				)
			}
		}
	}

	addComments(event) {
		// this.setState({ clicked: true })
		// this.page = sessionStorage.getItem("page");
		// // console.log("Add Comments!", event.pageX, event.pageY, Boolean.apply(sessionStorage.getItem('canCreateComment')));
		// let xpos = event.pageX;
		// let ypos = event.pageY;
		// let container = document.getElementById("comments-container");
		// let page = document.getElementsByClassName("react-pdf__Document")[0];
		// document.getElementsByClassName('react-pdf__Document')[0].style.margin = '0 auto';
		// // document.getElementsByClassName('react-pdf__Document')[0].style.paddingTop = '20%';
		// // container.appendChild(<CommentHolder x={xpos} y={ypos} />)
		// if (JSON.parse(sessionStorage.getItem("canCreateComment"))) {
		// 	let div = document.createElement("div");
		// 	container.appendChild(div);
		// 	ReactDOM.render(
		// 		<CommentHolder
		// 			x={xpos}
		// 			deviceHeight={page.offsetHeight}
		// 			y={ypos - this.commentHeading}
		// 			text=''
		// 			page={this.page}
		// 		/>,
		// 		div,
		// 	);
		// }
	}

	render() {
		return (
			<>
				{this.filterData()}
				<div
					className="comments-area"
					style={{ top: this.headingSize }}
					onClick={this.addComments}
				/>
			</>
		)
	}
}

export default Comments
