import styled from '@emotion/styled/macro'

export const LoadingContainer = styled.div`
	display: flex;
`

export const StyledSpinner = styled.svg`
	animation: rotate 2s linear infinite;
	width: 100px;
	margin: 25px auto;
	height: 100px;
	display: flex;
	align-self: center;
	& .path {
		stroke: #6a7ae9;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`
