import React, { useState, useEffect } from 'react'
// Removed unused imports
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	Typography
} from '@mui/material'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import footerImage from 'assets/Footer-Image.png'
import payoffOneImage from 'assets/login_image.png'
import EmailPopup from '../EmailPopup'
import { register } from 'api/auth'
import { getActionIdFromURL } from '../../utils/common.util'
import Carousel from 'react-multi-carousel'
// Correctly import SplitTreatments
import { SplitTreatments } from '@splitsoftware/splitio-react'

const NavbarHPOT = () => {
	const { t } = useTranslation()
	const FEATURE_FLAG = 'Carousel_NavbarHPot'
	const [email, setEmail] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [action_id, setActionId] = useState(null)
	// Removed slidesCount state since it's not used

	// Call the utility function inside a useEffect so it runs when the component mounts
	useEffect(() => {
		const actionIdFromURL = getActionIdFromURL() // Get action_id from URL
		setActionId(actionIdFromURL) // Set action_id to state
	}, [])

	const handleSign = async () => {
		setIsLoading(true)
		try {
			const payload = { email }
			if (action_id != null) {
				// Checks for both `null` and `undefined`
				payload.action_id = action_id
			}

			//*********************************************** */
			// console.log ('\nhandleSign Lauunched!')
			// console.log('payload =', payload)
			//*********************************************** */
			await register(payload)
			setIsLoading(false)
			setShowModal(true)
		} catch (error) {
			setEmail('')
			setIsLoading(false)
if (error.response) {
	toast(error.response.data.message)
} else if (error.request) {
	toast(error.request)
} else {
	toast(error.message)
} //ISM'T THIS ERROR HANDLING A BIT MESSY?
		}
	}

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1
		},
		tablet: {
			breakpoint: { max: 1024, min: 750 },
			items: 1
		},
		mobile: {
			breakpoint: { max: 750, min: 465 },
			items: 1
		},
		smallMobile: {
			breakpoint: { max: 465, min: 0 },
			items: 1
		}
	}

	// Removed the Split SDK setup code to avoid direct usage

	const renderContent = (treatmentWithConfig) => {
		// Revised to use treatmentWithConfig directly, avoiding direct client usage
		const { treatment, config } = treatmentWithConfig

		let flagConfig
		try {
			flagConfig = JSON.parse(config || '{}') // Default to an empty object if config is null
		} catch (e) {
			console.error('Error parsing feature flag config:', e)
			return
		}

		if (treatment === 'on' && flagConfig.show_carousel_on_navbar) {
			// Render content for the 'on' treatment
			return (
				<Grid item xs container direction="row" marginTop={5}>
					<Grid item xs={1} sm={1} md={2}>
						{' '}
					</Grid>

					<Grid item xs={12} sm={12} md={12} marginRight={2}>
						<div className="navbar-carousel">
							<Carousel
								swipeable={true}
								draggable={true}
								responsive={responsive}
								autoPlay={true}
								ssr
								infinite
								autoPlaySpeed={process.env.REACT_APP_CAROUSEL_SPEED}
								arrows={false}
								showDots={true}
								transitionDuration={500}
								renderButtonGroupOutside
							>
								{Array.from(
									{ length: JSON.parse(flagConfig.carousel_slides_count) },
									(_, i) => (
										<React.Fragment key={i}>
											<Grid item xs container direction="row">
												<Grid item xs={1} sm={1} md={2} />

												<Grid
													item
													xs={11}
													sm={11}
													md={10}
													className="payoff-one-text-container"
												>
													<Typography
														variant="h1"
														align="left"
														className="payoff-one-title h1"
													>
														{t(`home_carousel_1_title${i + 1}`)}
													</Typography>
												</Grid>
											</Grid>

											<Grid item xs container direction="row">
												<Grid item xs={1} sm={1} md={2} />
												<Grid
													item
													xs={11}
													sm={11}
													md={10}
													min-width="0"
													className="payoff-one-text-container"
												>
													<Typography
														variant="h5"
														className="payoff-one-text h7"
														align="justify"
														sx={{ py: '30px' }}
														paragraph
													>
														{t(`heroOT_carousel_1_desc${i + 1}`)}
													</Typography>
												</Grid>
											</Grid>
										</React.Fragment>
									)
								)}
							</Carousel>
						</div>
					</Grid>
				</Grid>
			)
		} else {
			// Render content for the 'off' treatment or control
			return (
				<>
					<Grid item xs container direction="row">
						<Grid item xs={1} sm={1} md={2} />

						<Grid
							item
							xs={11}
							sm={11}
							md={10}
							className="payoff-one-text-container"
						>
							<Typography
								variant="h1" //It does not make much sense keeping this formatting here. But we need it because it is responsible for setting a line-height smaller thatn 1.5, which seems to be the default one. Where is this "h1" setting defined from this point of view? AppSkin.js - where class h1 is defined - takes care of font size and font weight, not line height 
								align="left"
								className="payoff-one-title"
							>
								{t('home_title')}
							</Typography>
						</Grid>
					</Grid>

					<Grid item xs container direction="row">
						<Grid item xs={1} sm={1} md={2} />
						<Grid
							item
							xs={11}
							sm={11}
							md={10}
							min-width="0"
							className="payoff-one-text-container"
						>
							<Typography
								variant="h5"
								className="payoff-one-text"
								align="justify"
								sx={{ py: '30px' }}
								paragraph
							>
								{t('heroOT_desc')}
							</Typography>
						</Grid>
					</Grid>
				</>
			)
		}
	}

	return (
		<>
			<Grid container className="header" direction="row-reverse">
				<Grid
					item
					xs={0}
					sm={0}
					md={5.6}
					align={'right'}
					display={{ xs: 'none', sm: 'none', md: 'block' }}
				>
					<img
						src={payoffOneImage}
						alt="Improve your study"
						className="payoff-one-image"
					/>
				</Grid>

				<Grid
					item
					xs={1}
					sm={1}
					md={0}
					align={'right'}
					display={{ xs: 'block', sm: 'block', md: 'none' }}
				>
					<Paper />
				</Grid>
				<Grid item xs={11} sm={11} md={6.4}>
					{/* <Grid item xs container direction="column"> */}
					{/* <Grid item xs={12}> */}
					<Grid item xs container direction="row">
						<Grid item xs={5.5} height="100%" align="left">
							<img src={footerImage} alt="logo" className="logo-image" />
						</Grid>

						<Grid item xs={6.5} height="100%" className="login-btn-container">
							<Link to="/login">
								<Button
									variant="outlined"
									color="inherit"
									className="login-btn h6"
								>
									Login
								</Button>
							</Link>
						</Grid>
					</Grid>
					<Grid item xs container direction="row">
						<Grid item xs={12} sm={12} md={12}>
							<SplitTreatments names={[FEATURE_FLAG]}>
								{({ treatments, isReady }) => {
									if (isReady) {
										// Pass the treatment object directly to renderContent
										return renderContent(treatments[FEATURE_FLAG])
									}
									return null // Render nothing if not ready
								}}
							</SplitTreatments>

							<Grid item xs container direction="row">
								<Grid item xs={1} sm={1} md={2}>
									<Paper />
								</Grid>

								<Grid item xs={2}>
									<Button
										disabled={isLoading}
										variant="contained"
										className="join-btn h9"
										style={{ minWidth: 'unset', padding: '1% 3%' }}
										onClick={() => handleSign()}
									>
										{isLoading ? (
											<CircularProgress
												style={{ height: '20px', width: '20px' }}
												color="inherit"
											/>
										) : (
											t('hero_join_btn')
										)}
									</Button>
								</Grid>

								<Grid item xs={9} sm={9} md={8}>
									<input
										onInput={(e) => setEmail(e.target.value)}
										value={email}
										type="email"
										placeholder="Email"
										className="email-input h7"
										id="email"
										name="email"
									/>
								</Grid>
							</Grid>

							<Grid item xs container direction="row">
								<Grid item xs={12} height="20px">
									<Paper />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{/* </Grid> */}
					{/* </Grid> */}
				</Grid>
				<EmailPopup
					isopen={showModal}
					onClose={() => {
						setShowModal(false)
						setEmail('')
					}}
				/>
			</Grid>
		</>
	)
}

export default NavbarHPOT
