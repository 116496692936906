//Libraries
import React from 'react'
import ReactDOM from 'react-dom'
import toast from 'react-hot-toast'
import { withTranslation } from 'react-i18next'

//Components
// import CommentHandler from "./CommentHandler";
import DeleteConfirm from './DeleteConfirm'
import CommentModal from 'components/CommentModal'
import AlertBox from 'components/AlertBox'

//Styled components
import {
	StyledEditIcon,
	StyledDeleteIcon,
	StyledVisibilityIcon,
	CommentHolderContainer
} from './CommentHolderSkin.js'

//APIs
import { createNote, deleteLQARevNote, updateLQARevNote } from 'api/note'

class CommentHolder extends React.Component {
	constructor(props) {
		super(props)
		this.props = props
		const { notes } = this.props
		this.itemRef = React.createRef()
		this.state = {
			title: this.props.text,
			issave: false,
			isCancel: false,
			// titleApiList: [],
			notes,
			openAlertBox: false
		}
		this.selected = this.props.selected ? true : false
		this.xpos = props.x
		if (document.getElementsByClassName('react-pdf__Page')[0]) {
			// this.currentHeight = document.getElementsByClassName("react-pdf__Page")[0];
			this.currentHeight = this.props.currentHeight
			document.getElementsByClassName('react-pdf__Page')[0].style.margin =
				'0 auto'
		}
		// document.getElementsByClassName('react-pdf__Document')[0].style.paddingTop = '20%';
		// this.headingSize = document.getElementById('header').offsetHeight;
		this.headingSize = 55
		this.deviceHeight = props.deviceHeight
		this.yValue = props.y
		// if (!this.selected) {
		//   this.yValue -= this.headingSize;
		// }
		// this.ypos = this.yValue * (this.currentHeight / props.deviceHeight); previously it was like this
		this.ypos = Math.abs(this.yValue)
		// this.ypos = this.yValue * (this.currentHeight / props.deviceHeight);
		// The yHeight is used only on save.
		this.yHeight = props.y
		this.savePayload = {}
		this.iscancel = false
		this.title = ''
		this.visible =
			this.props.text === '' ||
			this.selected ||
			this.props.isShowModalOnSimpleNote
		this.isEditing = false
		this.editRef = null
		this.issaved = false
		this.isRender = this.props.isRender
		this.notes = this.props.no
		this.textsize = 15
		// if(window.screen.width <= 800) {
		//     this.textsize = 10;
		// }
		// else if(window.screen.width <=1024) {
		//     this.textsize = 12;
		// }
		// else if(window.screen.width <=1440) {
		//     this.textsize = 13;
		// }
		// else if(window.screen.width <=2000) {
		//     this.textsize = 18;
		// }

		this.range = 28

		this.positions = []
		// this.getPositions();

		this.viewComment = this.viewComment.bind(this)
		this.editComment = this.editComment.bind(this)
		this.deleteComment = this.deleteComment.bind(this)

		//console.log('Y Position: ', this.ypos, 'Text: ', props.text)
	}

	handleCancel = () => {
		this.setState({ isCancel: true })

		if (this.selected) {
			let parent = document.getElementsByClassName(
				'react-pdf__Page__textContent'
			)[0]
			for (let i = this.props.start; i <= this.props.end; i++) {
				const oldText = parent.children[i].textContent
				parent.children[i].innerHTML = oldText
			}
		}
	}

	handleSave = async ({ title, keyword, sideOne, sideTwo, stepInput }) => {
		const { t } = this.props

		const { getNoteByBookPage, page } = this.props
		try {
			const book_id = sessionStorage.getItem('selected-book')
			// }
			let payload = {
				note_book_id: book_id,
				note_step: parseInt(stepInput),
				note_keyword: keyword,
				note_side1: sideOne,
				note_side2: sideTwo,
				note_anchor: {
					x: this.xpos,
					y: this.ypos,
					deviceHeight: this.deviceHeight
				},
				note_page: this.props.page
			}
			if (this.props.selected) {
				const note_highlight = {
					start: this.props.start,
					end: this.props.end,
					side2Original: this.props.sidetwo
				}

				payload = { ...payload, note_highlight }
			}

			const token = sessionStorage.getItem('token')
			const response = await createNote({ token, note: payload })
			this.props.commentAdded('keyword')
			// console.log('handleSave, this.props =', this.props.sidetwo) //check what information comes with selection
			// console.log('handleSave, createNote Response=', response) //check what information comes with selection

			if (response.statusText === 'OK') {
				getNoteByBookPage(page)
			}

			if (this.isEditing) {
				localStorage.removeItem(
					this.editRef.current.children[0].children[0].innerText
				)
				this.isEditing = false
			}
			this.isRender = true
			this.setState({ title: keyword })
		} catch (ex) {
			if (ex.response) {
				// If the error has a response (meaning it's an HTTP error), display its message
				toast.error(ex.response.data.message)
			} else {
				// Generic error message for other types of errors
				toast.error(t('generic_error'))
			}
			if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
				console.log('message', ex)
			}
			getNoteByBookPage(page)
			this.props?.removeHighlight()
		}
	}

	handleUpdate = async ({ title, keyword, sideOne, sideTwo, stepInput }) => {
		const { t } = this.props
		try {
			const { note_id, getNoteByBookPage, page } = this.props
			const payload = {
				note_step: stepInput,
				note_keyword: keyword,
				note_side1: sideOne,
				note_side2: sideTwo
			}
			const response = await updateLQARevNote(note_id, payload)
			if (response.statusText === 'OK') {
				getNoteByBookPage(page)
			}
		} catch (ex) {
			if (ex.response) {
				// If the error has a response (meaning it's an HTTP error), display its message
				toast.error(ex.response.data.message)
			} else {
				// Generic error message for other types of errors
				toast.error(t('generic_error'))
			}
			if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
				console.log('message', ex)
			}
		}
	}

	viewComment = () => {
		const {
			note_payload: { note_keyword, note_side1, note_side2, note_step }
		} = this.props
		const div = document.createElement('div')
		const data = {
			title: this.props?.bookTitle,
			note_keyword: note_keyword,
			sideone: note_side1,
			sidetwo: note_side2,
			stepInput: note_step
		}
		ReactDOM.render(
			<CommentModal
				onOk={this.handleSave}
				open={true}
				data={data}
				canEdit={false}
				handleCancel={() => {}}
			/>,
			div
		)

		document.getElementById('tmp-modal-container').appendChild(div)
	}

	deleteComment = async () => {
		try {
			const { note_id, getNoteByBookPage, page } = this.props
			const response = await deleteLQARevNote(note_id)
			if (response.statusText === 'OK') {
				getNoteByBookPage(page)
				let parent = document.getElementsByClassName(
					'react-pdf__Page__textContent'
				)[0]
				for (let i = this.props.start; i <= this.props.end; i++) {
					const oldText = parent.children[i].textContent
					parent.children[i].innerHTML = oldText
				}
			}
		} catch (ex) {
			console.log('message::', ex)
		}
	}

	editComment = (keyword, ref) => {
		const {
			note_payload: { note_keyword, note_side1, note_side2, note_step }
		} = this.props
		const div = document.createElement('div')
		const data = {
			title: this.props?.bookTitle,
			note_keyword: note_keyword,
			sideone: note_side1,
			sidetwo: note_side2,
			stepInput: note_step
		}
		ReactDOM.render(
			<CommentModal
				onOk={this.handleUpdate}
				open={true}
				data={data}
				canEdit={true}
				handleCancel={() => {}}
			/>,
			div
		)

		document.getElementById('tmp-modal-container').appendChild(div)
	}

	getNonOverlappingPosition(ypos) {
		// console.log('Get Overlapping Position: ', ypos, this.positions);
		let y = ypos
		let top = y
		let bottom = y + this.range - 1
		// console.log("Range ", top, bottom);
		if (this.savePayload.y === ypos) {
			return
		}
		this.getPositions()
		for (let i = 0; i < this.positions.length; i++) {
			let topPos = this.positions[i].position + this.headingSize
			let bottomPos = this.positions[i].position + this.headingSize + this.range
			// console.log("Positions are: ", top, bottom, topPos, bottomPos);
			console.log(
				'this.positions[i].keyword',
				this.props.text,
				this.positions[i].keyword
			)
			if (this.positions[i].keyword === this.props.text) {
				continue
			}
			if (topPos <= top && bottomPos >= top) {
				console.log(
					'Overlappping!  first condition is true',
					topPos,
					bottomPos,
					top
				)
				y = topPos + this.range
				top = topPos + this.range + 0.001
				bottom = top + this.range + 0.001
			} else if (topPos <= bottom && bottomPos >= bottom) {
				console.log(
					'Overlappping!  second condition is true',
					topPos,
					bottomPos,
					bottom
				)
				console.log('Positions are: ', this.positions[i], this.props.text)
				y = topPos + this.range
				top = topPos + this.range + 0.001
				bottom = top + this.range + 0.001
			}
			// console.log(top, this.positions[i].position);
			// if(top <= this.positions[i].position) {
			//     break;
			// }
			// console.log("Position Range: ", topPos, bottomPos);
		}
		console.log('Y is: ', y)
		return y
	}

	deleteConfirm = (title) => {
		const div = document.createElement('div')
		console.log()
		ReactDOM.render(
			<DeleteConfirm title={title} onDelete={() => this.deleteComment()} />,
			div
		)

		document.getElementById('tmp-modal-container').appendChild(div)
	}

	render() {
		const pos = this.ypos * (this.currentHeight / this.deviceHeight)
		const commentStyle = {
			color: 'white',
			position: 'absolute',
			height: this.textsize + 7,
			top: this.selected || this.isRender ? pos : pos - 5,
			marginLeft: '2%',
			width: '95%'
		}
		const data = {
			title: this.props?.bookTitle,
			keyword: '',
			sideone: '',
			sidetwo: ''
		}
		if (this.selected) {
			data.sidetwo = this.props.sidetwo
		}

		const { t } = this.props
		return (
			<CommentHolderContainer>
				{!this.state.isCancel && (
					<div
						ref={this.itemRef}
						id="comment-item"
						className="comment-item"
						style={commentStyle}
					>
						<div className="comment">
							<p className="comment-text">{this.state.title}</p>
							<div className="comment-editor">
								<StyledEditIcon
									onClick={() => {
										const { expiration } = this.props
										if (expiration) {
											this.setState({ openAlertBox: true })
										} else {
											this.editComment(this.state.title, this.itemRef)
										}
									}}
								/>
								<StyledDeleteIcon
									onClick={() => this.deleteConfirm(this.state.title)}
								/>
								<StyledVisibilityIcon onClick={() => this.viewComment()} />
							</div>
						</div>
						<CommentModal
							onOk={this.handleSave}
							open={this.visible}
							data={data}
							canEdit={true}
							handleCancel={this.handleCancel}
						/>

						<AlertBox
							open={this.state.openAlertBox}
							description={t('subscriptionNoteUpdate')}
							setOpenAlertBox={(open) => this.setState({ openAlertBox: open })}
							title="Subscription Expired"
						/>
					</div>
				)}
			</CommentHolderContainer>
		)
	}
}

export default withTranslation()(CommentHolder)
