import { useState, useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { styled } from '@mui/material/styles'
import { UpdateBookModalForm } from './updateBookModalSkin'
import { updateBook } from 'api/book'
import Toast from 'shared/Toast'
import { queryClient } from 'App'
import { AppContainer } from '../../appSkin.js'
import useAuth from 'hooks/useAuth'

const BootstrapDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		width: '47.96875%'
	}
}))

const UpdateBookModal = ({
	book_id,
	book_title,
	book_author,
	book_issn,
	book_note,
	handleClose,
	modalOpen
}) => {
	const [bookTitle, setBookTitle] = useState(book_title)
	const [author, setAuthor] = useState(book_author)
	const [issn, setIssn] = useState(book_issn)
	const [note, setNote] = useState(book_note)
	const [bookUpdating, setBookUpdating] = useState(false)
	const [toastMessage, setToastMessage] = useState('')
	const [toastType, setToastType] = useState('')
	const [toastOpen, setToastOpen] = useState(false)
	const {
		auth: { token }
	} = useAuth()

	const handleSubmit = async (ev) => {
		ev.preventDefault()

		setBookUpdating(true)

		const result = await updateBook(
			book_id,
			{
				book_title: bookTitle,
				book_author: author,
				book_issnisbn: issn,
				book_notes: note
			},
			token
		)

		setBookUpdating(false)
		handleClose()
		setToastOpen(true)

		if (result.error) {
			setToastType('error')
			if (result.error.response) {
				setToastMessage(result.error.response.data.message)
			} else if (result.error.request) {
				setToastMessage(result.error.request)
			} else {
				setToastMessage(result.error.message)
			}
		} else {
			setToastType('success')
			setToastMessage('Book updated successfully!')
			queryClient.invalidateQueries('books-list')
		}
	}

	const toastClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setToastOpen(false)
	}
	const ref = useRef()

	const goUp = (id) => {
		id.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}

	const goDown = (id) => {
		id.scrollTo({
			top: id.scrollHeight,
			left: 0,
			behavior: 'smooth'
		})
	}

	const stopScroll = (id) => {
		id.scrollTop = id.scrollTop
	}

	return (
		<BootstrapDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={modalOpen}
			maxWidth={false}
		>
			<UpdateBookModalForm onSubmit={handleSubmit}>
				<AppContainer>
					<div className="modal_top">
						<input
							style={{ lineHeight: 2.5 }}
							className="h8 input-box font"
							type="text"
							placeholder="TITLE"
							value={bookTitle}
							onChange={(ev) => setBookTitle(ev.target.value)}
						/>

						<span className="close-icon" onClick={handleClose}>
							<CloseIcon fontSize="large" />
						</span>
					</div>

					<input
						style={{ lineHeight: 2.5 }}
						className="h8 input-box font"
						type="text"
						placeholder="AUTHOR"
						value={author}
						onChange={(ev) => setAuthor(ev.target.value)}
					/>

					<input
						style={{ lineHeight: 2.5 }}
						className="h8 input-box font"
						type="text"
						placeholder="ISSN / ISBN"
						value={issn}
						onChange={(ev) => setIssn(ev.target.value)}
					/>

					<div
						className="top"
						//onClick={handleUp}
						onPointerDown={(e) => goUp(ref.current)}
						onPointerUp={(e) => stopScroll(ref.current)}
					>
						<KeyboardArrowUpIcon style={{ cursor: 'pointer' }} />
					</div>
					<textarea
						ref={ref}
						rows="4"
						cols="50"
						maxLength="1000"
						className="h8 text-box font scrollbar"
						placeholder="YOUR NOTES"
						value={note}
						onChange={(ev) => setNote(ev.target.value)}
					/>
					<div
						className="bottom"
						//onClick={handleDown}
						onPointerDown={(e) => goDown(ref.current)}
						onPointerUp={(e) => stopScroll(ref.current)}
					>
						<KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
					</div>

					<div className="modal_bottom">
						<div className="note_buttons_container">
							<div className="h7 note font">
								Please note that in most cases we can retrieve Metadata directly
								from the uploaded file
							</div>
							<div className="buttons">
								<Button
									onClick={handleClose}
									variant="contained"
									className="h6 login-btn"
								>
									Cancel
								</Button>

								<Button
									type="submit"
									variant="contained"
									className="h6 login-btn"
									disabled={bookUpdating}
								>
									{bookUpdating ? (
										<CircularProgress
											style={{ height: '28px', width: '28px' }}
											color="inherit"
										/>
									) : (
										'Save'
									)}
								</Button>
							</div>
						</div>
					</div>
				</AppContainer>
			</UpdateBookModalForm>

			<Toast
				toastClose={toastClose}
				open={toastOpen}
				severity={toastType}
				message={toastMessage}
			/>
		</BootstrapDialog>
	)
}

export default UpdateBookModal
