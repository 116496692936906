import { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import LoadingIndicator from 'components/LoadingIndicator'
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone'
import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone'
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone'
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import Comments from './Comments'
import { PdfReaderContainer } from './pdfReaderSkin'
import { insertData } from '../../utils/idbIndexed'
import { findCommonSubstring } from 'utils/highlightSubstring'
import './style.css'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'react-query'
import { base64ToFile } from 'utils/common.util'
import { postBookContent } from 'api/book'

let pageContexts = []

const PdfReader = ({
	setOnLoadBook,
	book,
	setHandleOnChangePage,
	setNextPageHandle,
	currentPage,
	setCurrentPage,
	setNextPageNumber,
	setBookTotalPage,
	setCurrentOpenBookId,
	setPreviousPageNumber,
	setPreviousPageHandle,
	notes,
	commentsRef,
	pageHeight,
	setPageHeight,
	mouseUpEvent,
	getNoteByBookPage,
	bookIndexedDb,
	setLoadedCompletely,
	activePageStatus,
	commentAdded,
	resetComment,
	drawerToggle
}) => {
	const divRef = useRef(null)
	const pdfSectionRef = useRef(null)

	const [page, setPage] = useState(book.page)
	const [totalPage, setTotalPage] = useState(book.totalPage)
	const [clickNextPage, setClickNextPage] = useState(false)
	const [clickPeviousPage, setClickPeviousPage] = useState(false)
	const [loader, setLoader] = useState(false)
	let pageRef = useRef()
	let scrollIntervalIdValue = null
	const [btnBlock, setBtnBlock] = useState(false)
	const bookSrc = useRef()
	const {
		auth: {
			user: { expiration }
		}
	} = useAuth()
	// const [pageHeight,setPageHeight]=useState(0)
	// const [scrollValue, setScrollValue] = useState(0);

	useEffect(() => {
		if (pdfSectionRef.current) {
			pdfSectionRef.current?.focus()
		}
		resetComment()
	}, [commentAdded])

	useEffect(() => {
		if (pdfSectionRef.current) {
			pdfSectionRef.current?.focus()
		}
	}, [pdfSectionRef.current])

	useEffect(() => {
		if (pdfSectionRef.current) {
			pdfSectionRef.current?.focus()
		}
	}, [drawerToggle])

	useEffect(() => {
		if (clickNextPage) {
			setPreviousPageHandle(false)
			setNextPageNumber(page)
			setBookTotalPage(totalPage)
			setCurrentOpenBookId(book.id)
			setClickNextPage(false)
		}
		if (clickPeviousPage) {
			setNextPageHandle(false)
			setPreviousPageNumber(page)
			setBookTotalPage(totalPage)
			setCurrentOpenBookId(book.id)
			setClickPeviousPage(false)
		}
	}, [page, book.id, totalPage, clickPeviousPage, clickNextPage])

	useEffect(() => {
		if (currentPage) {
			setPage(currentPage)
		}
	}, [currentPage])

	let pageNo = 1
	let loadedPageNum = parseInt(sessionStorage.getItem('loaded-pages'))

	if (!loadedPageNum || loadedPageNum > pageContexts.length) {
		loadedPageNum = 0
		sessionStorage.setItem('loaded-pages', 0)
	}

	if (sessionStorage.getItem('page')) {
		pageNo = parseInt(sessionStorage.getItem('page'))
	}

	let pageNumber = pageNo
	let pageNum = parseInt(sessionStorage.getItem('page'))

	if (!pageNum) {
		pageNum = 1
	}

	// const data_obj = localStorage.getItem("bookmarks");
	// let data = {};
	// let values = data_obj ? JSON.parse(data_obj) : [];

	// values.sort((a, b) => {
	//   let a_obj = a;
	//   let b_obj = b;
	//   return a_obj.note_anchor.y - b_obj.note_anchor.y;
	// });

	// for (let v of values) {
	//   let tmp = v;
	//   data[tmp.note_keyword] = v;
	// }

	const { mutate, isLoading, isError, error } = useMutation(postBookContent)
	const setPageNumber = (num) => {
		pageNumber = num
	}

	const showComments = () => {
		const { bookTitle } = book
		ReactDOM.render(
			<Comments
				notes={notes}
				currentPage={currentPage}
				pageHeight={pageHeight}
				getNoteByBookPage={getNoteByBookPage}
				bookTitle={bookTitle}
				expiration={expiration}
			/>,
			document.getElementById('tmp-modal-container')
		)
	}

	const highlightSelected = () => {
		let parent = document.getElementsByClassName(
			'react-pdf__Page__textContent'
		)[0]
		if (parent && parent?.children?.length > 0) {
			for (let d of notes) {
				// console.log('highlightSelected, d= ', d)//check the content of d

				const start = d?.note_highlight?.start
				const end = d?.note_highlight?.end
				const side_two = d?.note_highlight?.side2Original
					? d?.note_highlight?.side2Original
					: d?.note_side2

				if (start && end) {
					for (let i = start; i <= end; i++) {
						const text = parent?.children[i]?.textContent
						const currentText = findCommonSubstring(text, side_two)
						const modifiedText = text.replace(
							currentText,
							`<span class="highlight">${currentText}</span>`
						)
						parent.children[i].innerHTML = modifiedText
					}
				}
			}
		}
	}
	// const savebookInIndexedDb = (bookUrl) => {
	// 	const xhr = new XMLHttpRequest()
	// 	xhr.open('GET', bookUrl)
	// 	xhr.responseType = 'blob'
	// 	xhr.onload = () => {
	// 		if (xhr.status === 200) {
	// 			const pdfBlob = xhr.response

	// 			// Create a new FileReader instance
	// 			const reader = new FileReader()

	// 			// Define the callback function for when the file is loaded
	// 			reader.onload = async () => {
	// 				// Store the binary data in localStorage
	// 				const base64Content = btoa(reader.result)
	// 				await insertData({ id: book.id, book: base64Content })
	// 				console.log('PDF file saved to localStorage')
	// 			}

	// 			// Read the PDF file as a binary string
	// 			reader.readAsBinaryString(pdfBlob)
	// 		} else {
	// 			console.log('Failed to fetch the PDF file from AWS S3')
	// 		}
	// 	}
	// 	xhr.send()
	// }

	useEffect(() => {
		let isMounted = true // Flag to track the mounted state

		const savebookInIndexedDb = (bookUrl) => {
			const xhr = new XMLHttpRequest()
			xhr.open('GET', bookUrl)
			xhr.responseType = 'blob'
			xhr.onload = () => {
				if (xhr.status === 200) {
					const pdfBlob = xhr.response

					// Create a new FileReader instance
					const reader = new FileReader()

					// Define the callback function for when the file is loaded
					reader.onload = async () => {
						if (!isMounted) return // Check if component is still mounted

						// Store the binary data in localStorage
						const base64Content = btoa(reader.result)
						await insertData({ id: book.id, book: base64Content })
						console.log('PDF file saved to localStorage')
					}

					// Read the PDF file as a binary string
					reader.readAsBinaryString(pdfBlob)
				} else {
					console.log('Failed to fetch the PDF file from AWS S3')
				}
			}
			xhr.send()
		}

		if (!bookIndexedDb) {
			savebookInIndexedDb(book.book)
		}
		bookSrc.current = bookIndexedDb
			? `data:application/pdf;base64,${bookIndexedDb.book}`
			: book.book
		setLoader(false)

		return () => {
			isMounted = false // Set the flag to false when the component unmounts
		}
	}, [book.book])

	// useEffect(() => {
	// 	if (!bookIndexedDb) {
	// 		savebookInIndexedDb(book.book)
	// 	}
	// 	bookSrc.current = bookIndexedDb
	// 		? `data:application/pdf;base64,${bookIndexedDb.book}`
	// 		: book.book
	// 	setLoader(false)
	// }, [book.book])

	useEffect(() => {
		commentsRef.current.scrollTop = 0
	}, [])

	function onDocumentLoadSuccess(pdf) {
		setOnLoadBook(true)
		setLoader(true)
		setTotalPage(pdf.numPages)
		setBookTotalPage(pdf.numPages)

		if (sessionStorage.getItem('page')) {
			setPageNumber(parseInt(sessionStorage.getItem('page')))
		} else {
			sessionStorage.setItem('page', pageNumber)
		}
	}

	const saveContent = (textContent, imagesArray) => {
		try {
			const files = []
			imagesArray.map((item, index) => {
				files.push(base64ToFile(item, `image${index}.png`))
			})
			const formData = new FormData()
			if (files.length > 0) {
				files.map((file) => {
					formData.append(`images`, file)
				})
			} else {
				formData.append(`images`, [])
			}

			formData.append('content', textContent)
			formData.append('pageN', currentPage)
			mutate({ formData, id: book?.id })
		} catch (error) {
			console.log('error', error)
		}
	}

	const pageLoaded = async (page) => {
		window.objs = []
		const SCRAPING = JSON.parse(process.env.REACT_APP_SCRAPE)
		var item = { images: [], texts: [] }
		var imageArray = []
		const textContentArray = await page.getTextContent()
		var textContent = textContentArray.items.map((item) => item.str).join('')
		const canvas = document.createElement('canvas')
		let context = canvas.getContext('2d', { willReadFrequently: true })

		let scalingDifference =
			Math.abs(divRef.current?.clientWidth - page.originalWidth) /
			page.originalWidth
		let newScale =
			page.originalWidth > divRef.current?.clientWidth
				? 1 - scalingDifference
				: 1 + scalingDifference
		let viewport = page.getViewport({ scale: newScale })
		if (viewport.width > divRef.current?.clientWidth) {
			scalingDifference =
				Math.abs(divRef.current?.clientWidth - viewport.width) / viewport.width
			viewport = page.getViewport({ scale: newScale - scalingDifference })
		} else if (viewport.width < divRef.current?.clientWidth) {
			scalingDifference =
				Math.abs(divRef.current?.clientWidth - viewport.width) / viewport.width
			viewport = page.getViewport({ scale: newScale + scalingDifference })
		}
		setPageHeight(viewport.height)
		const imageLayer = {
			beginLayout: () => {},
			endLayout: () => {
				if (SCRAPING) {
					if (!activePageStatus) {
						saveContent(textContent, imageArray)
					}
				}
			},
			appendImage: async ({
				left: x,
				top: y,
				width,
				height,
				imgData,
				objId
			}) => {
				try {
					if (!imgData) {
						// TODO: Fallback: commonObjs

						const img = page.objs.get(objId)
						const canvas = window.document.createElement('canvas')
						canvas.width = img.naturalWidth
						canvas.height = img.naturalHeight
						const context = canvas.getContext('2d')
						context.drawImage(img, 0, 0)
						imgData = context.getImageData(
							0,
							0,
							img.naturalWidth,
							img.naturalHeight
						)
					}

					if (!imgData) {
						alert('No image data!')
						throw new Error('No image data!')
					}

					const canvas = document.createElement('canvas')
					canvas.width = imgData.width
					canvas.height = imgData.height
					const context = canvas.getContext('2d')

					/** @type {Uint8ClampedArray} */
					let array

					if (imgData.data) {
						switch (imgData.data.length) {
							case imgData.width * imgData.height * 3: {
								array = new Uint8ClampedArray(
									imgData.width * imgData.height * 4
								)
								for (let index = 0; index < array.length; index++) {
									if (index % 4 === 3) {
										array[index] = 255
									} else {
										array[index] = imgData.data[~~(index / 4) * 3 + (index % 4)]
									}
								}

								break
							}

							case imgData.width * imgData.height * 4: {
								array = imgData.data
								break
							}

							default: {
								break
								// alert("Unknown imgData format!");
							}
						}

						context.putImageData(
							new ImageData(array, imgData.width, imgData.height),
							0,
							0
						)

						const data = {
							width: imgData.width,
							height: imgData.height,
							url: canvas.toDataURL()
						}
						item.images.push({ x, y, width, height, data })
						const image = document.createElement('img')
						imageArray.push(data.url)
						image.src = data.url

						image.width = width
						image.height = height
						// image.style = `position: absolute; top: ${
						//   y + headingSize + navSize + 20
						// }px; left: ${x + sidebarSize + 20}px`;

						image.style = `position: absolute; top: ${y}px; left: ${x}px`

						document
							.getElementById(`page-${page._pageIndex + 1}`)
							.appendChild(image)

						if (page._pageIndex === page - 1) {
							let pageImageContainer = document.getElementById(`page-${page}`)

							pageImageContainer.style.display = 'block'
						}
					}
				} catch (ex) {}
			}
		}
		page.render({ canvasContext: context, viewport, imageLayer: imageLayer })
		pageContexts.push({
			canvasContext: context,
			viewport,
			imageLayer: imageLayer
		})
		setLoadedCompletely(true)
	}

	// const changePageOnInput = (e) => {
	//   setCurrentPage(
	//     totalPage > e.target.value
	//       ? parseInt(e.target.value)
	//       : parseInt(totalPage)
	//   );
	//   setHandleOnChangePage(true);
	//   setBookTotalPage(totalPage);
	//   setPage(
	//     totalPage > e.target.value
	//       ? parseInt(e.target.value)
	//       : parseInt(totalPage)
	//   );
	//   setCurrentOpenBookId(book.id);
	//   // setScrollValue(0);
	// };

	// const handleKeyPress = (e) => {
	//   if (e.keyCode === 13) {
	//     changePageOnInput(e);
	//     // removeImagesInPage();
	//     // let tmp = parseInt(e.target.value);
	//     // tmp = Math.max(1, tmp);
	//     // pageNum = tmp;
	//     // let prevPg = document.getElementById(`page-${pageNumber}`);
	//     // prevPg.style.display = "none";
	//     // setPageNumber(tmp);
	//     // sessionStorage.setItem("page", tmp);
	//     // sessionStorage.setItem("scroll", window.scrollY);
	//     // let pg = document.getElementById(`page-${pageNumber}`);
	//     // pg.style.display = "block";
	//   }
	// };

	// const handleOnBlur = (e) => {
	//   changePageOnInput(e);
	// };
	const previousPage = () => {
		setClickPeviousPage(true)
		setPreviousPageHandle(true)
		setPage((p) => parseInt(p) - 1)
		setCurrentPage((p) => parseInt(p) - 1)
		setPageNumber((p) => parseInt(p) - 1)
		// setScrollValue(0);
	}

	const nextPage = async () => {
		setBtnBlock(true)
		setClickNextPage(true)
		setNextPageHandle(true)
		setPage((p) => parseInt(p) + 1)
		setCurrentPage((p) => parseInt(p) + 1)
		setNextPageNumber((p) => parseInt(p) + 1)
		setBtnBlock(false)

		// setScrollValue(0)
	}

	const scrollPdfPage = (value) => {
		divRef.current.scrollTop += value
		commentsRef.current.scrollTop += value
	}

	const loadingFun = (l) => {
		return <LoadingIndicator marginTop={'25%'} />
	}

	const onKeyDownHandler = (event) => {
		if (event.code === 'ArrowDown') {
			scrollPdfPage(scrollSpeed)
		}
		if (event.code === 'ArrowUp') {
			scrollPdfPage(-scrollSpeed)
		}
	}

	//SCastiglione - declaration & assignment of constant scrollSpeed
	const scrollSpeed = 8

	return (
		<PdfReaderContainer
			tabIndex="0"
			onKeyDown={onKeyDownHandler}
			ref={pdfSectionRef}
		>
			{page !== 1 && loader && (
				<ArrowCircleLeftTwoToneIcon
					className="control control-left"
					onClick={previousPage}
				/>
			)}

			{loader && (
				<ArrowCircleDownTwoToneIcon
					className="control control-down"
					onMouseDown={(e) => {
						scrollIntervalIdValue = setInterval(() => {
							divRef.current.scrollBy(0, scrollSpeed)
							commentsRef.current.scrollBy(0, scrollSpeed)
						}, 0)
					}}
					onMouseUp={() => clearInterval(scrollIntervalIdValue)}
					onMouseLeave={() => clearInterval(scrollIntervalIdValue)}
					onClick={() => scrollPdfPage(scrollSpeed)}
				/>
			)}

			{loader && (
				<ArrowCircleUpTwoToneIcon
					className="control control-up"
					onClick={() => scrollPdfPage(-scrollSpeed)}
					onMouseDown={(e) => {
						scrollIntervalIdValue = setInterval(() => {
							divRef.current.scrollBy(0, -scrollSpeed)
							commentsRef.current.scrollBy(0, -scrollSpeed)
						}, 0)
					}}
					onMouseUp={() => clearInterval(scrollIntervalIdValue)}
					onMouseLeave={() => clearInterval(scrollIntervalIdValue)}
				/>
			)}

			<section
				id="pdf-section"
				className="d-flex flex-column align-items-center w-100"
				style={{ width: '100%', height: 'auto', overflow: 'hidden' }}
				ref={divRef}
				onWheel={(event) => {
					scrollPdfPage(event.deltaY)
				}}
			>
				<Document
					loading={loadingFun}
					file={bookSrc.current}
					onLoadSuccess={onDocumentLoadSuccess}
					onSourceError={(error) => {
						setOnLoadBook(true)
						console.log(`error from source... ${error.message}`)
					}}
					onLoadError={(error) => {
						setOnLoadBook(true)
						console.log(`error while loading... ${error.message}`)
					}}
					pageNum={page}
					options={{ workerSrc: '/pdf.worker.js' }}
				>
					<div id={`page-${page}`} key={page} className="pdf-page">
						<div onMouseUp={mouseUpEvent}>
							<Page
								pageNumber={currentPage}
								onLoadError={(error) =>
									console.log(`Error while loading document! ${error.message}`)
								}
								onRenderSuccess={pageLoaded}
								onGetTextSuccess={highlightSelected}
								canvasRef={pageRef}
								wrap={true}
								width={divRef.current?.clientWidth}
							/>
						</div>
					</div>
				</Document>
			</section>

			<div id="image-layer" />

			{/* <div id="footer" key={page} className="footer">
        <p>
          {!loader ? (
            <>Loading...</>
          ) : (
            <>
              Page:
              <input
                type={"number"}
                className="page-input"
                onChange={(e) => handleOnChange(e)}
                value={onChangeInput}
                style={{ width: "25px" }}
                min={1}
                max={totalPage}
                onKeyDown={handleKeyPress}
                onBlur={handleOnBlur}
              />{" "}
              of {totalPage}
            </>
          )}
        </p>
      </div> */}

			{page !== totalPage && !btnBlock && loader && (
				<ArrowCircleRightTwoToneIcon
					className="control control-right"
					style={{
						right: !JSON.parse(localStorage.getItem('closed')) ? '17%' : '22%'
					}}
					onClick={nextPage}
				/>
			)}
			{showComments()}
		</PdfReaderContainer>
	)
}

export default PdfReader
