// Adjusted to not use the useLocation hook
export const SendPageViewEventToGTM = () => {
	const fullPath =
		window.location.origin + window.location.pathname + window.location.search

	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({
		event: 'pageview',
		page_location: fullPath,
		page_path: window.location.pathname,
		page_title: document.title,
		page_referrer: document.referrer
	})
}
